import { Button, Flex } from '@chakra-ui/react';
import { ParagraphActivityListFragmentFragment } from '../../../generated/types';
import Container from '../../Layout/Container';
import TeaserGrid from '../../Common/TeaserGrid';
import Wrapper from '../Wrapper';
import React from 'react';
import ParagraphTitle from '../ParagraphTitle';
import Link from 'next/link';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import getAppComponent from '../../../utils/getAppComponent';
import { useStore } from '../../../store/provider';
import addFilterToSearchPageUrl from '../../../utils/addFilterToSearchPageUrl';

interface ActivityListProps {
  data: ParagraphActivityListFragmentFragment;
  isInArticleLayout?: boolean;
}

const ActivityList: React.FC<ActivityListProps> = ({
  data,
  isInArticleLayout,
}) => {
  const store = useStore();

  const TeaserComponent = getAppComponent(
    process.env.APP_ID!,
    store.config.usesNewLayout,
    'ActivityTeaser'
  );

  const teaserProps = isInArticleLayout
    ? {
        imageContainerMaxWidth: '320px',
        noOfLines: 3,
        hideCTA: true,
      }
    : {};

  const Inner = (
    <>
      {(data.title || data.subtitle) && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={4}
        >
          <ParagraphTitle label={data.title} sub={data.subtitle} />
        </Flex>
      )}

      <TeaserGrid
        columns={store.config.usesNewLayout ? 1 : 3}
        spacing={store.config.usesNewLayout ? 16 : 8}
      >
        {data.data!.items!.map((teaser) => (
          <TeaserComponent key={teaser!.id} data={teaser!} {...teaserProps} />
        ))}
      </TeaserGrid>

      <Flex flex={1} justifyContent="center" mt={8}>
        <Link
          href={addFilterToSearchPageUrl(
            data.category.searchPageUrl ||
              `/entdecken/${data.category.frontendUrl}`,
            data.countries,
            data.regions
          )}
          passHref
        >
          <Button variant="outline" as="a" colorScheme="brand">
            {data.linktext || 'Mehr entdecken'}{' '}
            <ArrowForwardIcon marginLeft={2} fontSize="sm" />
          </Button>
        </Link>
      </Flex>
    </>
  );

  return (
    <Wrapper className="ParagraphActivityList">
      {isInArticleLayout ? Inner : <Container>{Inner}</Container>}
    </Wrapper>
  );
};

export default ActivityList;
